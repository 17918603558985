import CloseBtn from 'components/buttons/button-close';

const RefreshPageHandler = ({ closeModal, title }) => (
  <div className="error-message">
    <CloseBtn onClick={ () => closeModal() } />
    <h5 className="title">{title}</h5>
    <hr />
    <p>
      Well that’s not what’s supposed to happen. Refresh the page and let’s try
      that again.
    </p>
    <hr />
    <div className="handlers-buttons">
      <button
        type="button"
        className="button button-cancel"
        tabIndex={ 2 }
        onClick={ () => closeModal() }
      >
        Cancel
      </button>
      <button
        type="button"
        className="button button-cta "
        tabIndex={ 1 }
        autoFocus
        onClick={ () => location.reload() }
      >
        Refresh
      </button>
    </div>
  </div>
);

export default RefreshPageHandler;
