/* eslint no-console: "off" */
import { MARKETING_SITE_EVENT_IDENTIFIER } from '../constants';
import { params as parseParams } from './partials/params';
import trackMercuryEvent from './trackMercuryEvent';

const params = () => parseParams();


// The trigger configured in GTM is that referrer does not contain "wheniwork."

export default function trackEvent(category, action, label = null, value = null, annotations = {}) {

  // attributes in data catalog for this event
  const fields = {
    category,
    action,
    label,
    value,
    annotations,
    queryParams: params(),
  };
   
  if (category === null || action === null) {
    console.error('You must provide an category and action to track an website event');
    return;
  }
  // // window.mercury object has public methods for track, page, and identify
  return trackMercuryEvent(MARKETING_SITE_EVENT_IDENTIFIER, fields);
}