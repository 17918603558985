import trackSiteEvent from 'utils/mercury/trackSiteEvent';

import './styles/handlers.scss';
import RefreshPageHandler from './refreshPageHandler';

type HandlerProps = {
  closeModal: any;
  errors: any;
};

const Handlers = ({ errors, closeModal }:HandlerProps) => {
  trackSiteEvent();

  function handleError() {
    return (
      <RefreshPageHandler
        title="Something went wrong"
        closeModal={ closeModal }
      />
    );
  }
  return (
    <div className="handlers-overlay">
      <div className="handlers-container">{errors && handleError()}</div>
    </div>
  );
};

export default Handlers;
