import { useState, Fragment } from 'react';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';

import FontIcon from 'components/icons';
import Handlers from 'components/modal/handlers';

import './styles/subscribe.scss';
import './styles/free-time-card-calculator-download.scss';
import CloseBtn from '../buttons/button-close';
import Vero from '../forms/vero';
ReactModal.setAppElement('#___gatsby');

type FreeTimeCardCalculatorDownloadProps = {
  buttonClassName?: string;
  buttonDisabled?: boolean;
  buttonIcon?: string;
  button_text?: string;
  dataReady?: boolean;
  downloadUrl?: string;
  formType: string;
  hasError?: any;
  image: {
    source_url: string;
  };
  title?: string;
};

const FreeTimeCardCalculatorDownload = ({
  title,
  image,
  button_text,
  formType,
  buttonClassName,
  buttonDisabled,
  buttonIcon,
  downloadUrl,
  hasError,
}:FreeTimeCardCalculatorDownloadProps) => {
  // This component needs the modal state to update based on
  // the success of the input field. You need to add the state updates inside the hooked modal component
  //  which is why this modal looks a little different than others.

  const [showModal, hideModal] = useModal(
    ({ onExited }) => {
      const [onSuccess, setOnSuccess] = useState(false);
      const updateSuccessState = data => {
        setOnSuccess(data);
      };

      return (
        <ReactModal
          isOpen
          onAfterClose={ onExited }
          onRequestClose={ hideModal }
          className={ `default-modal-wrapper free-time-card-calculator-modal ${onSuccess &&
            'success-state'}` }
          overlayClassName="default-modal-overlay"
        >
          <section
            data-section="freeTimeCardCalculatorModal"
            className="free-time-card-calculator-container"
          >
            <CloseBtn onClick={ hideModal } />
            {!onSuccess && <h2 className="has-text-centered">{title}</h2>}
            <Fragment>
              <Vero
                formType={ formType }
                emailField
                uniqueId="veroFreeTimeCardCalculator"
                submitBtnText="Sign Me Up!"
                downloadUrl={ downloadUrl }
                updateSuccess={ updateSuccessState }
              />
              {onSuccess && (
                <button
                  type="button"
                  className="button is-filled-green is-rounded button-success"
                  onClick={ () => hideModal() }
                >
                  Ok, thanks
                </button>
              )}
              <img
                loading="lazy"
                src={ image.source_url }
                alt="organization illustration"
                height="220"
              />
            </Fragment>
            {hasError && <Handlers errors={ hasError } closeModal={ hideModal } />}
          </section>
        </ReactModal>
      );
    },
    [downloadUrl, hasError]
  );

  return (
    <button
      className={ `button button-submit is-rounded is-filled-green ${buttonClassName &&
        buttonClassName}` }
      type="submit"
      disabled={ buttonDisabled }
      onClick={ () => showModal() }
    >
      {button_text || 'Download Time Card'}
      {buttonIcon && <FontIcon icon={ buttonIcon } />}
    </button>
  );
};

export default FreeTimeCardCalculatorDownload;
