import { ChangeEvent, forwardRef, HTMLProps } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { format } from 'date-fns';

import FontIcon from 'components/icons';

import 'components/accordion/styles/accordion.scss';
import { updateTotalStyle } from './constants';
import TableRow from './table-row';
type TimeClockCalculatorMobileProps = Omit<
  HTMLProps<HTMLInputElement>,
  'id' | 'onChange' | 'handleChange' | 'values' | 'as' | 'day'
> & {
  data?: any;
  daysOfTheWeek?: any;
  handleChange: any;
  header?: any;
  labels?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  values?: any;
};

const TimeClockCalculatorMobile = forwardRef<
  HTMLInputElement,
  TimeClockCalculatorMobileProps
>(function TimeClockCalculatorMobile(props, ref: any) {
  const data = props;

  return (
    <Accordion className="accordion box">
      {data.daysOfTheWeek.map((day: Date, index) => (
        <AccordionItem key={ index }>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="btn-focus" tabIndex={ -1 }>
                <h3 className="is-size-6">{format(day, 'cccc')}</h3>
                <output
                  className={ updateTotalStyle(data.value[`row${index}Total`]) }
                >
                  {data.value[`row${index}Total`]}
                </output>
                <FontIcon icon="chevron-up" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <table>
              <tbody>
                <TableRow
                  day={ day }
                  rowCount={ index }
                  handleChange={ data.handleChange }
                  value={ data.value }
                  labels={ data.labels }
                />
              </tbody>
            </table>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
});

export default TimeClockCalculatorMobile;
