import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import AllTheDevices from 'components/content-blocks/all-the-devices';
import FreeTimeCardCalculator from 'components/forms/free-time-card-calculator/free-time-card-calculator';
import Yoast from 'components/globals/yoast';
import { HeroDefaultPage } from 'components/hero/default-page';

import './styles/free-time-card-calculator.scss';
const FreeTimeCard = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "free-time-card-calculator" } }) {
        edges {
          node {
            wordpress_id
            acf {
              default_page_hero {
                title
                sub_title
                copy
                background_image {
                  source_url
                }
              }
              free_time_card_calculator {
                header {
                  icon_title
                  title
                  name_icon
                  name_placeholder_text
                  calendar_button_text
                  calendar_button_icon
                  clear_button_text
                }
                calculator {
                  header {
                    label {
                      text
                      tooltip
                    }
                  }
                }
              }
              basic_title_subtitle_copy_image {
                title
                wysiwyg
              }
              all_the_devices {
                title
                copy
                signup {
                  title
                  modal_launch_button_text
                  modal_title
                }
              }
              subscribe_modal {
                title
                image {
                  source_url
                }
              }
            }
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                  source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.default_page_hero;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const freeTimeCardCalulator = pageContext.free_time_card_calculator;
  const howToUseCalculator = pageContext.basic_title_subtitle_copy_image;
  const modalContext = pageContext.subscribe_modal;
  const allTheDevices = pageContext.all_the_devices;

  return (
    <Layout hideSignupBox>
      <Yoast { ...yoast } />
      <div className="ftcc-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultPage { ...hero } imageDesktopOnly />
        <FreeTimeCardCalculator
          { ...freeTimeCardCalulator }
          modalContext={ modalContext }
        />
        <section className="how-to-use-section section">
          <div className="container">
            <h2 className="headline">{howToUseCalculator.title}</h2>
            <div
              className="copy"
              dangerouslySetInnerHTML={ { __html: howToUseCalculator.wysiwyg } }
            ></div>
          </div>
        </section>
        <AllTheDevices { ...allTheDevices } />
      </div>
    </Layout>
  );
};
export default FreeTimeCard;
