import { useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { endOfWeek, format, startOfWeek } from 'date-fns';

import './styles/date-week-picker.scss';
import 'react-day-picker/lib/style.css';
import CloseBtn from '../buttons/button-close';
import { startOfThisWeek } from '../forms/free-time-card-calculator/constants';
ReactModal.setAppElement('#___gatsby');
import FontIcon from 'components/icons';

type DateWeekPickerProps = {
  buttonIcon?: string;
  buttonText?: string;
  className?: string;
  updateWeekEndDate: any;
  updateWeekStartDate: any;
  weekEndDate: any;
  weekStartDate: any;
};

const DateWeekPicker = ({
  updateWeekStartDate,
  updateWeekEndDate,
  weekStartDate,
  weekEndDate,
  buttonIcon,
  buttonText,
}:DateWeekPickerProps) => {
  // This component needs the modal state to update based on
  // the success of the input field. You need to add the state updates inside the hooked modal component
  //  which is why this modal looks a little different than others.

  const [selectedWeekStart, setSelectedWeekStart] = useState(weekStartDate) as any;
  const [selectedWeekEnd, setSelectedWeekEnd] = useState(weekEndDate) as any;

  useEffect(() => {
    setSelectedWeekStart(weekStartDate);
    setSelectedWeekEnd(weekEndDate);
  }, [weekStartDate, weekEndDate]);

  const formattedDate: any = () =>
    `${format(selectedWeekStart, 'PP')} - ${format(selectedWeekEnd, 'PP')}`;

  const [showModal, hideModal] = useModal(({ onExited }) => {
    const handleDayClick = (day: Date) => {
      setSelectedWeekStart(startOfWeek(day, { weekStartsOn: 1 }));
      setSelectedWeekEnd(endOfWeek(day, { weekStartsOn: 1 }));
      updateWeekStartDate(startOfWeek(day, { weekStartsOn: 1 }));
      updateWeekEndDate(endOfWeek(day, { weekStartsOn: 1 }));
      hideModal();
    };

    const [hoverRange, setHoverRange] = useState({
      from: startOfWeek(new Date(), { weekStartsOn: 1 }),
      to: endOfWeek(new Date(), { weekStartsOn: 1 }),
    });

    const getWeekRange = date => ({
      from: startOfWeek(date, { weekStartsOn: 1 }),
      to: endOfWeek(date, { weekStartsOn: 1 }),
    });
    const handleDayEnter = date => setHoverRange(getWeekRange(date));

    const handleDayLeave = () =>
      setHoverRange({ from: selectedWeekStart, to: selectedWeekEnd });

    const modifiers: any = {
      hoverRange,
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
    };

    return (
      <ReactModal
        isOpen
        onAfterClose={ onExited }
        onRequestClose={ hideModal }
        className="default-modal-wrapper date-week-picker-modal"
        overlayClassName="default-modal-overlay"
      >
        <section
          data-section="dateWeekPickerModal"
          className="date-week-picker-modal-container"
        >
          <CloseBtn onClick={ hideModal } />
          <DayPicker
            onDayClick={ (day: Date) => handleDayClick(day) }
            firstDayOfWeek={ 1 }
            showOutsideDays
            modifiers={ modifiers }
            onDayMouseEnter={ handleDayEnter }
            onDayMouseLeave={ handleDayLeave }
          />
        </section>
      </ReactModal>
    );
  });

  return (
    <button
      className="date-week-picker-button button is-rounded"
      type="button"
      onClick={ showModal }
      title="Week Picker"
    >
      {selectedWeekStart === startOfThisWeek ? buttonText : formattedDate()}
      <FontIcon icon={ buttonIcon } />
    </button>
  );
};

export default DateWeekPicker;
