import { camelCase } from 'lodash';

import FontIcon from 'components/icons';

import './styles/tooltip.scss';

type TooltipProps = {
    icon?: string,
    position?: string,
    tooltipContent: any,
    tooltipLabel: string
}

const Tooltip = ({ tooltipContent, tooltipLabel, icon, position }:TooltipProps) => (
  <div className="tooltip">
    <button 
      type="button"
      aria-describedby={ camelCase(tooltipLabel) }
    >
      <FontIcon icon={ icon ?? 'help' } />
    </button>
    <div role="tooltip" id={ camelCase(tooltipLabel) } className={ `hide-tooltip has-tooltip-${position ?? 'right'}` } >
      {tooltipContent}
    </div>
  </div>
);


export default Tooltip;     