import { ChangeEvent, forwardRef, HTMLProps } from 'react';
import { format } from 'date-fns';
import { Field } from 'formik';

import FontIcon from 'components/icons';
import DateWeekPicker from 'components/modal/date-week-picker';

import './styles/table-header.scss';
import InputField from '../fields/input';
import FormLabel from '../fields/label';
import FormGroup from '../form-group';
import { endOfThisWeek, startOfThisWeek } from './constants';

type TableHeaderProps = Omit<
  HTMLProps<HTMLInputElement>,
  'id' | 'onChange' | 'handleChange' | 'values' | 'as' | 'day'
> & {
  data?: any;
  handleChange: any;
  header?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  updateWeekEndDate: any;
  updateWeekStartDate: any;
  value?: any;
  values?: any;
  weekEndDate: any;
  weekStartDate: any;
};

const TableHeader = forwardRef<HTMLInputElement, TableHeaderProps>(
  function TableHeader(props, ref: any) {
    const data = props;

    const handleReset = () => {
      data.updateWeekStartDate(startOfThisWeek);
      data.updateWeekEndDate(endOfThisWeek);
    };

    return (
      <header className="ftcc-form-header columns">
        <div className="column column-left is-two-fifths">
          <h2 className="title">
            <FontIcon icon={ data.header.icon_title } />
            {data.header.title}
          </h2>
        </div>
        <div className="column column-right">
          <FormGroup className={ data.value.name === '' ? 'is-optional' : '' }>
            <FormLabel hidden htmlFor="name">
              Name
            </FormLabel>
            <InputField
              id="name"
              name="name"
              className="has-icon"
              onChange={ data.handleChange }
              value={ data.value.name }
              title="Full Name"
              placeholder={ data.header.name_placeholder_text }
              icon="employee"
            />
          </FormGroup>
          <DateWeekPicker
            buttonIcon={ data.header.calendar_button_icon }
            buttonText={ data.header.calendar_button_text }
            weekStartDate={ data.weekStartDate }
            weekEndDate={ data.weekEndDate }
            updateWeekStartDate={ weekStartDate =>
              data.updateWeekStartDate(weekStartDate)
            }
            updateWeekEndDate={ weekEndDate =>
              data.updateWeekEndDate(weekEndDate)
            }
          />
          <Field
            type="hidden"
            value={
              (data.value.weekOf = `${format(
                data.weekStartDate,
                'PP'
              )} - ${format(data.weekEndDate, 'PP')}`)
            }
            name="weekOf"
            id="weekOf"
            onChange={ data.handleChange }
          />
          <button
            type="reset"
            title="Reset Form"
            className="button button-reset is-rounded"
            onClick={ () => handleReset() }
          >
            {data.header.clear_button_text}
          </button>
        </div>
      </header>
    );
  }
);

export default TableHeader;
