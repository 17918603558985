import { useState } from 'react';
import axios from 'axios';
import { eachDayOfInterval } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FreeTimeCardCalculatorDownload from 'components/modal/free-time-card-calculator-download';

import './styles/free-time-card-calculator.scss';
import { endOfThisWeek, startOfThisWeek, updateTotalStyle } from './constants';
import FreeTimeCardCalculatorDesktop from './free-time-card-calculator-desktop';
import FreeTimeCardCalculatorMobile from './free-time-card-calculator-mobile';
import TableHeader from './table-header';

type FreeTimeCardCalculatorProps = {
  calculator: {
    header: {
      label: [
        {
          text: string;
          tooltip: string;
        }
      ];
    };
  };
  header: {
    calendar_button_icon: string;
    calendar_button_text: string;
    clear_button_text: string;
    icon_title: string;
    name_icon: string;
    name_placeholder_text: string;
    title: string;
  };
  modalContext: {
    image: {
      source_url: string;
    };
    title: string;
  };
};

export type FTCCFormValues = {
  name?: string;
  weekOf: any;
};

const FreeTimeCardCalculator = ({
  header,
  modalContext,
  calculator,
}:FreeTimeCardCalculatorProps) => {
  const [weekStartDate, setWeekStartDate] = useState(startOfThisWeek);
  const [weekEndDate, setWeekEndDate] = useState(endOfThisWeek);
  const [downloadUrl, setDownloadUrl] = useState(null) as any;
  const [apiError, setAPIError] = useState(false);

  const daysOfTheWeek = eachDayOfInterval({
    start: weekStartDate as any,
    end: weekEndDate as any,
  });

  const breakpoints = useBreakpoint();

  const rows = {};
  for (let i = 0; i < 7; i++) {
    rows[`row${i}Date`] = '';
    rows[`row${i}StartHours`] = '';
    rows[`row${i}StartMinutes`] = '';
    rows[`row${i}StartAMPM`] = 'AM';
    rows[`row${i}StartTime`] = '00:00:AM';
    rows[`row${i}EndHours`] = '';
    rows[`row${i}EndMinutes`] = '';
    rows[`row${i}EndAMPM`] = 'AM';
    rows[`row${i}EndTime`] = '00:00:AM';
    rows[`row${i}BreakHours`] = '';
    rows[`row${i}BreakMinutes`] = '';
    rows[`row${i}BreakTime`] = 0.0;
    rows[`row${i}Total`] = 0.0;
  }

  const initialValues = {
    name: '',
    unformattedWeek: `${startOfThisWeek} - ${endOfThisWeek}`,
    weekOf: '',
    totalTime: 0.0,
    ...rows,
  };

  const rootPath = `${process.env.GATSBY_MARKETING_BACKEND_BASE_URL}/pdf-generator/timesheet`;

  const responseData = data =>
    axios
      .post(rootPath, {
        name: data.name,
        weekOf: data.weekOf,
        totalTime: data.totalTime,
        days: [
          {
            date: data.row0Date,
            startTime: `${data.row0StartTime}`,
            endTime: `${data.row0EndTime}`,
            break: data.row0BreakTime,
            total: data.row0Total,
          },
          {
            date: data.row1Date,
            startTime: `${data.row1StartTime}`,
            endTime: `${data.row1EndTime}`,
            break: data.row1BreakTime,
            total: data.row1Total,
          },
          {
            date: data.row2Date,
            startTime: `${data.row2StartTime}`,
            endTime: `${data.row2EndTime}`,
            break: data.row2BreakTime,
            total: data.row2Total,
          },
          {
            date: data.row3Date,
            startTime: `${data.row3StartTime}`,
            endTime: `${data.row3EndTime}`,
            break: data.row3BreakTime,
            total: data.row3Total,
          },
          {
            date: data.row4Date,
            startTime: `${data.row4StartTime}`,
            endTime: `${data.row4EndTime}`,
            break: data.row4BreakTime,
            total: data.row4Total,
          },
          {
            date: data.row5Date,
            startTime: `${data.row5StartTime}`,
            endTime: `${data.row5EndTime}`,
            break: data.row5BreakTime,
            total: data.row5Total,
          },
          {
            date: data.row6Date,
            day: data.row6Day,
            startTime: `${data.row6StartTime}`,
            endTime: `${data.row6EndTime}`,
            break: data.row6BreakTime,
            total: data.row6Total,
          },
        ],
      })
      .then(response => {
        setDownloadUrl(response.data.url);
      })
      .catch(error => {
        console.log(error);
        setAPIError(true);
      });

  const handleSubmit = (data: any) => responseData(data);

  const labels = calculator.header.label;

  return (
    <section className="free-time-card-calculator-section">
      <div className="container">
        <Formik initialValues={ initialValues } onSubmit={ handleSubmit }>
          {({ handleChange, values }) => {
            const timeCardTotal =
              values['row0Total'] +
              values['row1Total'] +
              values['row2Total'] +
              values['row3Total'] +
              values['row4Total'] +
              values['row5Total'] +
              values['row6Total'];

            return (
              <Form>
                <TableHeader
                  handleChange={ handleChange }
                  value={ values }
                  header={ header }
                  weekStartDate={ weekStartDate }
                  weekEndDate={ weekEndDate }
                  updateWeekStartDate={ weekStartDate =>
                    setWeekStartDate(weekStartDate)
                  }
                  updateWeekEndDate={ weekEndDate => setWeekEndDate(weekEndDate) }
                />
                {breakpoints.md ? (
                  <FreeTimeCardCalculatorMobile
                    handleChange={ handleChange }
                    value={ values }
                    daysOfTheWeek={ daysOfTheWeek }
                    header={ header }
                    labels={ labels }
                  />
                ) : (
                  <FreeTimeCardCalculatorDesktop
                    handleChange={ handleChange }
                    value={ values }
                    daysOfTheWeek={ daysOfTheWeek }
                    header={ header }
                    labels={ labels }
                  />
                )}
                <hr />
                <div className="ftcc-form-footer">
                  <FreeTimeCardCalculatorDownload
                    formType="freeTimeCardCalculatorDownload"
                    buttonIcon="print"
                    hasError={ apiError }
                    buttonDisabled={
                      timeCardTotal !== 0 && timeCardTotal > 0
                        ? false
                        : true
                    }
                    { ...modalContext }
                    downloadUrl={ downloadUrl }
                  />
                  <p className="form-total">
                    Total Hours
                    <span
                      className={ updateTotalStyle(timeCardTotal) }
                      title="Week Hours Total"
                    >
                      {timeCardTotal === 0 ? '0.00' : timeCardTotal}
                    </span>
                  </p>
                  <Field
                    type="hidden"
                    id="timeCardTotal"
                    name="timeCardTotal"
                    onChange={ handleChange }
                    value={ (values.totalTime = timeCardTotal) }
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};
export default FreeTimeCardCalculator;
