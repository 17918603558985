import { ChangeEvent, forwardRef, HTMLProps, Fragment } from 'react';

import Tooltip from 'components/tooltips/tooltip';

import TableRow from './table-row';

type TimeClockCalculatorDesktopProps = Omit<
  HTMLProps<HTMLInputElement>,
  'id' | 'onChange' | 'handleChange' | 'values' | 'as' | 'day'
> & {
  data?: any;
  daysOfTheWeek?: any;
  handleChange: any;
  header?: any;
  labels?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  values?: any;
};

const TimeClockCalculatorDesktop = forwardRef<
  HTMLInputElement,
  TimeClockCalculatorDesktopProps
>(function TimeClockCalculatorDesktop(props, ref: any) {
  const data = props;
  return (
    <Fragment>
      <table className="ftcc-form-table table">
        <thead className="ftcc-form-table-head">
          <tr>
            {data.labels.map((label, index) => (
              <th key={ index }>
                {label.text}
                {label.tooltip && (
                  <Tooltip
                    tooltipLabel={ label.text }
                    tooltipContent={ label.tooltip }
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.daysOfTheWeek.map((day: Date, index) => (
            <Fragment key={ index }>
              <TableRow
                day={ day }
                rowCount={ index }
                handleChange={ data.handleChange }
                value={ data.value }
              />
            </Fragment>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
});

export default TimeClockCalculatorDesktop;
