import { ChangeEvent, forwardRef, HTMLProps } from 'react';
import { format, set } from 'date-fns';
import { Field } from 'formik';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import SelectField from 'components/forms/fields/select';

import './styles/table-row.scss';
import InputField from '../fields/input';
import FormLabel from '../fields/label';
import FormGroup from '../form-group';
import { updateTotalStyle } from './constants';

type TableRowProps = Omit<
  HTMLProps<HTMLInputElement>,
  'id' | 'onChange' | 'handleChange' | 'values' | 'as' | 'day'
> & {
  data?: any;
  day: any;
  handleChange: any;
  labels?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  value?: any;
  values?: any;
};

const TableRow = forwardRef<HTMLInputElement, TableRowProps>(function TableRow(
  props,
  ref: any
) {
  const data = props;
  const rowDayName: string = format(data.day, 'cccc');
  const rowDate: Date = data.day;
  const startHours = data.value[`row${data.rowCount}StartHours`];
  const startMinutes = data.value[`row${data.rowCount}StartMinutes`];
  const startAMPM = data.value[`row${data.rowCount}StartAMPM`];
  const endHours = data.value[`row${data.rowCount}EndHours`];
  const endMinutes = data.value[`row${data.rowCount}EndMinutes`];
  const endAMPM = data.value[`row${data.rowCount}EndAMPM`];
  const breakHours = data.value[`row${data.rowCount}BreakHours`];
  const breakMinutes = data.value[`row${data.rowCount}BreakMinutes`];
  const breakpoints = useBreakpoint();

  let startTime: Date | string = rowDate;
  let endTime: Date | string = rowDate;
  let breakTime: number | string = `${breakHours}:${breakMinutes}`;

  //formatting date and letting am and pm change dynamically when the field updates
  startTime =
    format(
      set(startTime, { hours: startHours, minutes: startMinutes }),
      'hh:mm:a'
    ).slice(0, -2) + startAMPM;
  endTime =
    format(
      set(endTime, { hours: endHours, minutes: endMinutes }),
      'hh:mm:a'
    ).slice(0, -2) + endAMPM;

  // The user doesn't use inputs in a given row or just changes AM and PM will not let 12AM through
  if (
    (endTime === '12:00:AM' &&
      startTime === '12:00:AM' &&
      endHours === '' &&
      startHours === '') ||
    (endHours === '' && endAMPM === 'PM') ||
    (startHours === '' && startAMPM === 'PM')
  ) {
    startTime = '00:00:AM';
    endTime = '0:00:AM';
  }

  const forceNumberOnlyAndMaxLength = e => {
    if (e.target.value.length === 2) e.preventDefault();
    if (!/\d/.test(e.key)) e.preventDefault();
  };

  const rowCalculations = (startTime, endTime, breakTime) => {
    const times = [0, 0];
    const max = times.length;
    const nextDay = 1440;
    const telveHoursInMinutes = 720;
    let totalMinutes;

    const starts = (startTime || '').split(':');
    const ends = (endTime || '').split(':');
    const breaks = (breakTime || '').split(':');

    const startsAM = starts[2] === 'AM';
    const startsPM = starts[2] === 'PM';
    const endsAM = ends[2] === 'AM';
    const endsPM = ends[2] === 'PM';

    // normalize time values
    for (let index = 0; index < max; index++) {
      starts[index] = isNaN(parseInt(starts[index]))
        ? 0
        : parseInt(starts[index]);
      ends[index] = isNaN(parseInt(ends[index])) ? 0 : parseInt(ends[index]);
      breaks[index] = isNaN(parseInt(breaks[index]))
        ? 0
        : parseInt(breaks[index]);
    }

    if (starts[2] === 'AM' && ends[2] === 'PM' && ends[0] === 12)
      ends[0] = ends[0] - 12;
    if (starts[2] === 'AM' && ends[2] === 'PM' && ends[0] !== 12)
      ends[0] = ends[0] + 12;
    if (starts[2] === 'AM' && ends[2] === 'AM' && starts[0] === 12)
      starts[0] = 0;

    startTime = starts[0] = starts[0] * 60 + starts[1];
    endTime = ends[0] = ends[0] * 60 + ends[1];
    breakTime = breaks[0] = breaks[0] * 60 + breaks[1];

    // correcting math when 12 is a factor
    if (
      startsAM &&
      endsAM &&
      endTime > startTime &&
      ends[0] === telveHoursInMinutes + ends[1]
    ) {
      totalMinutes = endTime - telveHoursInMinutes - startTime - breakTime;
    } else if (
      startsPM &&
      endsPM &&
      endTime > startTime &&
      starts[0] === telveHoursInMinutes &&
      ends[0] === telveHoursInMinutes + ends[1]
    ) {
      totalMinutes = endTime - telveHoursInMinutes - breakTime;
    } else if (
      startsAM &&
      endsAM &&
      endTime > startTime &&
      ends[0] !== telveHoursInMinutes
    ) {
      totalMinutes = endTime - startTime - breakTime;
    } else if (
      startsPM &&
      endsAM &&
      starts[0] === telveHoursInMinutes &&
      ends[0] === telveHoursInMinutes
    ) {
      totalMinutes = telveHoursInMinutes - breakTime;
    } else if (
      startsAM &&
      endsAM &&
      starts[0] === telveHoursInMinutes &&
      ends[0] === telveHoursInMinutes
    ) {
      totalMinutes = endTime - startTime - breakTime;
    } else if (
      startsAM &&
      endsAM &&
      endTime < startTime &&
      ends[0] !== telveHoursInMinutes
    ) {
      totalMinutes = nextDay - startTime + endTime - breakTime;
    } else if (startsPM && endsAM && ends[0] === telveHoursInMinutes) {
      totalMinutes = endTime - startTime - breakTime;
    } else if (
      startsPM &&
      endsAM &&
      starts[0] === telveHoursInMinutes &&
      ends[0] !== telveHoursInMinutes
    ) {
      totalMinutes = telveHoursInMinutes + endTime - breakTime;
    } else if (
      startsAM &&
      endsAM &&
      endTime > startTime &&
      ends[0] === telveHoursInMinutes
    ) {
      totalMinutes = nextDay - startTime - breakTime;
    } else if (startsPM && endsPM && ends[0] === telveHoursInMinutes) {
      totalMinutes =
        telveHoursInMinutes - startTime + telveHoursInMinutes - breakTime;
    } else if (
      (startsAM && endsPM && starts[0] === telveHoursInMinutes) ||
      (startsPM && endsAM && starts[0] === telveHoursInMinutes) ||
      (startsPM && endsPM && starts[0] === telveHoursInMinutes)
    ) {
      totalMinutes = endTime - breakTime;
    } else if (startsPM && endsAM && starts[0] !== telveHoursInMinutes) {
      totalMinutes = telveHoursInMinutes - startTime + endTime - breakTime;
    } else {
      totalMinutes = endTime - startTime - breakTime;
    }

    const totalTime = totalMinutes / 60;
    const total = Math.round(totalTime * 100) / 100;

    return total;
  };

  const rowBreakCalculations = breakTime => {
    const times = [0, 0];
    const max = times.length;
    const breaks = (breakTime || '').split(':');
    // normalize time values
    for (let index = 0; index < max; index++) {
      breaks[index] = isNaN(parseInt(breaks[index]))
        ? 0
        : parseInt(breaks[index]);
    }
    breakTime = breaks[0] = breaks[0] * 60 + breaks[1];
    breakTime = breakTime / 60;
    const total = Math.round(breakTime * 100) / 100;
    return total;
  };

  const rowTotal: string | number = rowCalculations(
    startTime,
    endTime,
    breakTime
  );

  breakTime = rowBreakCalculations(breakTime);

  // Add space between AM and PM so the output matches the design and backend doesn't need to do any reformatting.
  startTime = startTime.replace(/(:AM|:PM)/, ' ') + startAMPM;
  endTime = endTime.replace(/(:AM|:PM)/, ' ') + endAMPM;

  const showLabels = index => {
    if (data.labels) return data.labels[index].text;
  };

  return (
    <tr className="ftcc-form-table-row">
      <td className="date">
        {rowDayName}
        <Field
          type="hidden"
          value={
            (data.value[`row${data.rowCount}Date`] = format(rowDate, 'PP'))
          }
          name={ data.value[`row${data.rowCount}Date`] }
          onChange={ data.handleChange }
        />
      </td>
      <td className="start-time">
        <div className="input-group">
          <FormGroup className={ Boolean(data.labels) ? 'has-label' : '' }>
            <FormLabel
              hidden={ Boolean(!breakpoints.md) }
              htmlFor={ `row${data.rowCount}StartHours` }
            >
              {showLabels(1)}
            </FormLabel>
            <InputField
              id={ `row${data.rowCount}StartHours` }
              name={ `row${data.rowCount}StartHours` }
              onChange={ data.handleChange }
              value={ startHours }
              placeholder="00"
              type="number"
              title="Hours"
              pattern="\d*"
              onKeyPress={ forceNumberOnlyAndMaxLength }
            />
          </FormGroup>
          <span className="spacer">:</span>
          <FormGroup>
            <FormLabel hidden htmlFor={ `row${data.rowCount}StartMinutes` }>
              Start Minutes
            </FormLabel>
            <InputField
              id={ `row${data.rowCount}StartMinutes` }
              name={ `row${data.rowCount}StartMinutes` }
              onChange={ data.handleChange }
              value={ startMinutes }
              placeholder="00"
              type="number"
              title="Minutes"
              pattern="\d*"
              onKeyPress={ forceNumberOnlyAndMaxLength }
            />
          </FormGroup>
          <span className="spacer">:</span>
          <FormGroup>
            <FormLabel hidden htmlFor={ `row${data.rowCount}StartAMPM` }>
              Start AM or PM
            </FormLabel>
            <SelectField
              id={ `row${data.rowCount}StartAMPM` }
              name={ `row${data.rowCount}StartAMPM` }
              onChange={ data.handleChange }
              value={ startAMPM }
              title="AM PM"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </SelectField>
          </FormGroup>
          <Field
            type="hidden"
            id={ `row${data.rowCount}StartTime` }
            name={ `row${data.rowCount}StartTime` }
            onChange={ data.handleChange }
            value={ (data.value[`row${data.rowCount}StartTime`] = startTime) }
          />
        </div>
      </td>
      <td className="end-time">
        <div className="input-group">
          <FormGroup className={ Boolean(data.labels) ? 'has-label' : '' }>
            <FormLabel
              hidden={ Boolean(!breakpoints.md) }
              htmlFor={ `row${data.rowCount}EndHours` }
            >
              {showLabels(2)}
            </FormLabel>
            <InputField
              id={ `row${data.rowCount}EndHours` }
              name={ `row${data.rowCount}EndHours` }
              onChange={ data.handleChange }
              value={ endHours }
              placeholder="00"
              type="number"
              title="Hours"
              pattern="\d*"
              onKeyPress={ forceNumberOnlyAndMaxLength }
            />
          </FormGroup>
          <span className="spacer">:</span>
          <FormGroup>
            <FormLabel hidden htmlFor={ `row${data.rowCount}EndMinutes` }>
              End Minutes
            </FormLabel>
            <InputField
              id={ `row${data.rowCount}EndMinutes` }
              name={ `row${data.rowCount}EndMinutes` }
              onChange={ data.handleChange }
              value={ endMinutes }
              placeholder="00"
              type="number"
              title="Minutes"
              pattern="\d*"
              onKeyPress={ forceNumberOnlyAndMaxLength }
            />
          </FormGroup>
          <span className="spacer">:</span>
          <FormGroup>
            <FormLabel hidden htmlFor={ `row${data.rowCount}EndAMPM` }>
              End AM or PM
            </FormLabel>
            <SelectField
              id={ `row${data.rowCount}EndAMPM` }
              name={ `row${data.rowCount}EndAMPM` }
              onChange={ data.handleChange }
              value={ endAMPM }
              title="AM PM"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </SelectField>
          </FormGroup>
          <Field
            type="hidden"
            id={ `row${data.rowCount}EndTime` }
            name={ `row${data.rowCount}EndTime` }
            onChange={ data.handleChange }
            value={ (data.value[`row${data.rowCount}EndTime`] = endTime) }
          />
        </div>
      </td>
      <td className="break-time">
        <div className="input-group">
          <FormGroup className={ Boolean(data.labels) ? 'has-label' : '' }>
            <FormLabel
              hidden={ Boolean(!breakpoints.md) }
              htmlFor={ `row${data.rowCount}BreakHours` }
            >
              {showLabels(3)}
            </FormLabel>
            <InputField
              id={ `row${data.rowCount}BreakHours` }
              name={ `row${data.rowCount}BreakHours` }
              onChange={ data.handleChange }
              value={ breakHours }
              placeholder="00"
              type="number"
              title="Hours"
              pattern="\d*"
              onKeyPress={ forceNumberOnlyAndMaxLength }
            />
          </FormGroup>{' '}
          <span className="spacer">:</span>
          <FormGroup>
            <FormLabel hidden htmlFor={ `row${data.rowCount}BreakMinutes` }>
              Break Minute
            </FormLabel>
            <InputField
              id={ `row${data.rowCount}BreakMinutes` }
              name={ `row${data.rowCount}BreakMinutes` }
              onChange={ data.handleChange }
              value={ breakMinutes }
              placeholder="00"
              type="number"
              title="Minutes"
              pattern="\d*"
              onKeyPress={ forceNumberOnlyAndMaxLength }
            />
          </FormGroup>
          <Field
            type="hidden"
            id={ `row${data.rowCount}BreakTime` }
            name={ `row${data.rowCount}BreakTime` }
            onChange={ data.handleChange }
            value={ (data.value[`row${data.rowCount}BreakTime`] = breakTime) }
          />
        </div>
      </td>
      <td
        className={ `row-total ${updateTotalStyle(rowTotal)} ${
          Boolean(data.labels) ? 'has-label' : ''
        }` }
        title="Day Hour Total"
      >
        <FormLabel
          hidden={ Boolean(!breakpoints.md) }
          htmlFor={ `row${data.rowCount}Total` }
        >
          {showLabels(4)}
        </FormLabel>
        {rowTotal === 0 ? '0.00' : rowTotal}
        <Field
          type="hidden"
          id={ `row${data.rowCount}Total` }
          name={ `row${data.rowCount}Total` }
          onChange={ data.handleChange }
          value={ (data.value[`row${data.rowCount}Total`] = rowTotal) }
        />
      </td>
    </tr>
  );
});

export default TableRow;
